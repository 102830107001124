@-webkit-keyframes grow {
    0% {
        -webkit-transform: scale(0.8);
    }
    50% {
        -webkit-transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.8);
    }
}

.grow-anim {
    animation: grow 1.7s infinite;
}

.rc-select {
    width: 100%;
}

html, body, #react-app {
    height: 100%;
    width: 100%
}


.tabs {
    width: 400px;
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
}

.tab-links {
    margin: 0;
    padding: 0;
    border: 1px solid #CCC;
    border-bottom: 1px solid #868686;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tab-link {
    padding: 0 15px;
    cursor: pointer;
    border: 1px solid transparent;
    display: inline-block;
}

.tab-link-active {
    font-weight: bold;
    border: 1px solid #868686;
    border-bottom: 1px solid white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: white;
}

/*To hide the arrows*/
/* Chrome */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
/* //Firefox: */
input[type="number"] {
-moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
-moz-appearance: number-input;
}
/* Other */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}